/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box {
  position: relative;
  width: 21rem;
  font-size: map-get($font-sizes, '-1');

  .search-box-icon {
    position: absolute;
    color: var(--#{$prefix}gray-400);
    top: 50%;
    left: 0.9rem;
    transform: translateY(-48%);
  }
  .search-box-close-btn-container {
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .search-input {
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 1.7;
    border-radius: 50rem;
    box-shadow: none;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  .dropdown-menu {
    border: 1px solid $border-color;
    font-family: $font-family-base;
    left: 0;
    margin-top: map-get($spacers, 2);
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    width: 100%;
    position: absolute;
  }

  [data-bs-toggle='search']:not(.show) {
    + [data-bs-dismiss='search'] {
      display: none;
    }
  }

  .file-thumbnail {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.safari {
  .search-box .search-input {
    line-height: 2;
  }
}
