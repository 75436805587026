@import 'falcon/theme.scss';

$samm-governance: #587792;
$samm-design: #e08742;
$samm-implementation: #a27c5d;
$samm-verification: #824f62;
$samm-operations: #757680;
$lightness-factor: 50%;

.bg-samm-governance {
  background-color: $samm-governance !important;
}

.bg-samm-governance-light {
  background-color: scale-color($samm-governance, $lightness: $lightness-factor) !important;
}

.bg-samm-design {
  background-color: $samm-design !important;
}

.bg-samm-design-light {
  background-color: scale-color($samm-design, $lightness: $lightness-factor) !important;
}
